document.addEventListener("turbolinks:load", () => {
	console.log('loaded tippyUserMenu');
	const userMenuContainer = document.getElementById('user-menu-container')

	tippy('#current-user-button', {
		content: userMenuContainer,
		allowHTML: true,
		trigger: 'click',
		theme: 'light-border',
		interactive: true,
		placement: 'auto',
		animation: 'scale-subtle',
		onShow(instance) {
			userMenuContainer.classList.remove('hidden')
		}
	})
})
