document.addEventListener("turbolinks:load", function() {
  const has_annex = document.getElementById('annex-toggle');
  const annexDiv = document.getElementById('annex-content-container');

  // if (has_annex) {
  //   if (has_annex.checked) {
  //     annexDiv.classList.remove('hidden')
  //     tinymce.remove();
  //     tinymce.init({
  //       selector:'.tinymce',
  //       plugins: 'table image lists',
  //       relative_urls: false,
  //       images_upload_url: '/tinymce_assets/image',
  //       height: "450",
  //       toolbar: 'styleselect | bold italic | undo redo | table | image | bullist numlist'
  //     });
  //   }

  //   has_annex.addEventListener('change', (event) => {
  //     if (event.target.checked) {
  //       annexDiv.classList.remove('hidden');
  //       tinymce.init({
  //         selector:'.tinymce',
  //         plugins: 'table image lists',
  //         relative_urls: false,
  //         images_upload_url: '/tinymce_assets/image',
  //         height: "450",
  //         toolbar: 'styleselect | bold italic | undo redo | table | image | bullist numlist'
  //       });
  //     } else {
  //       annexDiv.className += ' hidden'
  //     }
  //   })
  // }

  if (document.querySelector('.tinymce-general-description')) {
    tinymce.init({
      selector:'.tinymce-general-description',
      plugins: 'table image lists',
      relative_urls: false,
      images_upload_url: '/tinymce_assets/image',
      height: "450",
      toolbar: 'styleselect | bold italic | undo redo | table | image | bullist numlist'
    });
  }
});