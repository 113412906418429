document.addEventListener("turbolinks:load", function() { //TODO remove jQuery
  $('#user_type').change(function(){
    selection = $(this).val();
    
    switch(selection)
    {
      case 'Customer':
        console.log('customer');
        $('#client_select').show();
        $('#user_client_id').removeAttr("disabled");
        $('#user_email').removeAttr("disabled");
        $('#invite-submit').removeAttr("disabled");
        $('#user_email').attr('placeholder', 'Enter the client email address');
        break;
      default: 'User'
        console.log('user');
        $('#client_select').hide();
        $('#user_client_id').attr('disabled', true);
        $('#user_email').removeAttr("disabled");
        $('#user_email').attr('placeholder', 'Enter email of Task Master employee you want to invite');
        break;
    }
  });
})