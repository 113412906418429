document.addEventListener("turbolinks:load", function() {
  const select = document.getElementById('time-ticket-report-select');
  if (select) {
    select.addEventListener('change', (e) => {
      console.log(select.value);
      const customerName = document.querySelector('#timesheet-form-client-name');
      const customerAddress = document.querySelector('#timesheet-form-client-address');
      const customerContactName = document.querySelector('#timesheet-form-client-contact-name');
      const customerContactPhone = document.querySelector('#timesheet-form-client-contact-phone');
      const location = document.querySelector('#timesheet-form-location');
      const project = document.querySelector('#timesheet-form-project');
      const purchaseOrder = document.querySelector('#timesheet-form-purchase-order');
      const afeNumber = document.querySelector('#timesheet-form-afe');
      const customerJob = document.querySelector('#timesheet-form-customer-job-number');
  
      Rails.ajax({
        url: "/fetch_report_for_timesheet",
        type: "POST",
        data: `id=${select.value}`,
        success: function(data) {
          console.log(data);
          customerName.innerHTML = data.customer_name;
          customerAddress.innerHTML = data.customer_address;
          customerContactName.innerHTML = data.customer_contact_name;
          customerContactPhone.innerHTML = data.customer_contact_phone;
          location.innerHTML = data.location;
          project.innerHTML = data.project;
          purchaseOrder.innerHTML = data.po_number;
          afeNumber.innerHTML = data.afe_number;
          customerJob.innerHTML = data.customer_job_number;
        }
      })
    });
  }
})