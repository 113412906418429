document.addEventListener("turbolinks:load", function() {
  const clientCurrentUserButton = document.getElementById('client_current_user_button');

  if (clientCurrentUserButton) {
    const userSubMenu = clientCurrentUserButton.nextElementSibling;
    const userMenuItems = Array.from(userSubMenu.children);

    let hideUserMenu = true;

    clientCurrentUserButton.addEventListener('click', () => {
      if (hideUserMenu) {
        userMenuItems.forEach(item => item.classList.remove('hidden'));
        hideUserMenu = false;
      } else {
        userMenuItems.forEach(item => item.classList.add('hidden'));
        hideUserMenu = true;
      }
    });  
  }
});
