document.addEventListener("turbolinks:load", function() {
	const pdfPreviewContents = document.querySelectorAll('.pdf-preview-content')

	pdfPreviewContents.forEach(preview => {
		tippy(preview.parentElement.querySelector('.preview-pdf-button'), {
			content: preview,
			allowHTML: true,
			trigger: 'click',
			theme: 'light-border',
			interactive: true,
			placement: 'auto',
			animation: 'scale',
			maxWidth: 'none',
			onTrigger(instance, e) {
				preview.classList.remove('hidden')
			}
		})
	})
})
