document.addEventListener("turbolinks:load", function() {
  const currentUserButton = document.getElementById('current_user_button');

  if (currentUserButton) {
    const userSubMenu = currentUserButton.nextElementSibling;
    const userMenuItems = Array.from(userSubMenu.children);

    let hideUserMenu = true;

    currentUserButton.addEventListener('click', () => {
      if (hideUserMenu) {
        userSubMenu.classList.add('openSubMenuUser');
        userMenuItems.forEach(item => item.classList.add('visible'));
        hideUserMenu = false;
      } else {
        userSubMenu.classList.remove('openSubMenuUser');
        userMenuItems.forEach(item => item.classList.remove('visible'));
        hideUserMenu = true;
      }
    });  
  }
  
});