document.addEventListener("turbolinks:load", () => {
	const newReportListContainer = document.getElementById('new-report-list-container')

	tippy('#new-report-menu-button', {
		content: newReportListContainer,
		allowHTML: true,
		trigger: 'click',
		theme: 'light-border',
		interactive: true,
		placement: 'auto',
		animation: 'scale-subtle',
		onShow(instance) {
			newReportListContainer.classList.remove('hidden')
		}
	})
})
