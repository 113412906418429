document.addEventListener("turbolinks:load", function() {
  $("span.uid-label:contains('UT')").addClass("label-ut");
  $("span.uid-label:contains('RT')").addClass("label-rt");
  $("span.uid-label:contains('MT')").addClass("label-mt");
  $("span.uid-label:contains('GT')").addClass("label-gt");
  $("span.uid-label:contains('PT')").addClass("label-pt");
  $("span.uid-label:contains('HT')").addClass("label-ht");
  $("span.uid-label:contains('VT')").addClass("label-vt");
  $("span.uid-label:contains('PM')").addClass("label-pm");
  $("span.uid-label:contains('PA')").addClass("label-pa");
  $("span.uid-label:contains('WI')").addClass("label-wi");
  $("span.uid-label:contains('ET')").addClass("label-et");
  $("span.uid-label:contains('FT')").addClass("label-ft");
});