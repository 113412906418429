document.addEventListener("turbolinks:load", function() {
	const url = document.location.href;

	console.log(url.match(/clients/))

	if (url.match(/admin\/technicians/)) {
		document.querySelectorAll('#manage_technicians, #manage-users-button-link').forEach(element => element.classList.add('active'));
	} else if (url.match(/admin\/admins/)) {
		document.getElementById('manage_admin').classList.add('active');
	} else if (url.match(/admin\/clients/)) {
		document.getElementById('manage_clients').classList.add('active');
	} else if (url.match(/admin\/suspended/)) {
		document.getElementById('manage_suspended').classList.add('active');
	} else if (url.match(/clients/)) {
		document.getElementById('manage-clients-button-link')?.classList.add('active');
	} else if (url.match(/assistants/)) {
		document.getElementById('manage-assistants-button-link').classList.add('active');
	} else if (url.match(/time_sheets/)) {
		document.getElementById('manage-invoices-button-link').classList.add('active');	
	} else if (url.match(/admin\/pending/)) {
		document.querySelectorAll('#manage_pending, #manage-users-button-link').forEach(element => element.classList.add('active'));
	} else if (url.match(/updates/)) {
		document.getElementById('updates-button-link').classList.add('active');
	}
})