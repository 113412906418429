document.addEventListener("turbolinks:load", () => {
	console.log('loaded tippyCloneMenu');
	const cloneContent = document.getElementById('clone-content')

	tippy('#clone-dropdown-button', {
		content: cloneContent,
		allowHTML: true,
		trigger: 'click',
		theme: 'light-border',
		interactive: true,
		placement: 'auto',
		animation: 'scale-subtle',
		onShow(instance) {
			cloneContent.classList.remove('hidden')
		}
	})
})
