// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
console.log('Hello World from Webpacker')

// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import Rails from "@rails/ujs"
import MicroModal from 'micromodal';
import Tingle from 'tingle.js'

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
MicroModal.init()
Rails.start()

require('jquery')
// require("@rails/ujs").start()
require("@rails/activestorage").start()
require('turbolinks').start()
require("channels")
require('userMenuButton')
require('clientUserMenuButton')
require('manageObjects')
require("packs/hello_coffee")
require('uidColorLabels')
require('tippyTips')
require('userTypeSelect')
require('timeTicketReportSelect')
require("chartkick")
require("chart.js")
require("navState")
require("filmDescriptionSelect")
require("addRemoveItems")
require("emails")
require("manageObjectAlt")
require("annexController")
require('abstract-chosen')
require('chosen.jquery.js')
require('utOptionalSelect')
require('tippyHtml')
require('tippyNewReport')
require('tippyUserMenu')
require('proxyUserSelect')
require('handlePdfPreview')
require('tippyCloneMenu')

window.tingle = Tingle;

// window.jQuery = $; // works
// window.$ = $;

global.Rails = Rails;
