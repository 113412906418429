document.addEventListener("turbolinks:load", function() {
  const proxyUserSelect = document.getElementById('report-form-proxy-select')
	const originalReportUserQualSelect = document.getElementById('report_user_qualification')

  if (proxyUserSelect) {
    proxyUserSelect.addEventListener('change', () => {

			for (a in originalReportUserQualSelect.options) { 
				originalReportUserQualSelect.options.remove(0)
			}

      Rails.ajax({
        url: "/fetch_quals_for_proxy_user",
        type: "POST",
        data: `id=${proxyUserSelect.value}`,
        success: function(data) {
					data.forEach(element => {
						let option = document.createElement("option")
						option.value = element[0]
						option.text = element[0]
						originalReportUserQualSelect.appendChild(option)
					});
        }
      })
    })
  }
})