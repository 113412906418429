document.addEventListener("turbolinks:load", function() {
	const utOpionalSelect = document.getElementById('ut-optional-select')
	const urlString = window.location.href;
	const asmeSection = document.getElementById('asme-additional-section')
	const w59Section = document.getElementById('w59-additional-section')
	const asmeFirstField = document.getElementById('report_hardwares_alt__weld_id')
	const w59FirstField = document.getElementById('report_hardwares_dup__weld_id')

	if (utOpionalSelect) {
		utOpionalSelect.addEventListener('change', (e) => {

			function highlightFor(id,color,seconds) {
				console.log('**', id)
				var element = document.getElementById(id)
				var origcolor = element.style.backgroundColor
				element.style.backgroundColor = color;
				var t = setTimeout(function() {
					element.style.backgroundColor = origcolor;
				},(seconds*300));
			}
	
	
			if (e.target.value === 'ASME') {
				asmeSection.classList = 'panel hidden'
				w59Section.classList = 'panel hidden'
	
				asmeSection.classList.remove('hidden')
				highlightFor('asme-additional-section', 'yellow', 3)
			}
	
			if (e.target.value === 'W59') {
				asmeSection.classList = 'panel hidden'
				w59Section.classList = 'panel hidden'
	
				w59Section.classList.remove('hidden')
				highlightFor('w59-additional-section', 'yellow', 3)
			}
	
			if (e.target.value === 'None') {
				asmeSection.classList = 'panel hidden'
				w59Section.classList = 'panel hidden'
			}
		})
	
		if (urlString.indexOf("edit") > -1) {
			// utOpionalSelect.style.display = "none";
	
			if (asmeFirstField.value != '' )  {
				asmeSection.classList.remove('hidden')
			}
	
			if (w59FirstField.value != '' )  {
				w59Section.classList.remove('hidden');
			}
	
			// if (asmeFirstField.value && w59FirstField.value != '' )  {
			// 	dgsFieldSet.classList.remove('hidden');
			// }
		}
	}
});