var AbstractChosen;

AbstractChosen = (function() {
function AbstractChosen(form_field, options) {
  this.form_field = form_field;
  this.options = options != null ? options : {};
  if (!AbstractChosen.browser_is_supported()) {
    return;
  }
  this.is_multiple = this.form_field.multiple;
  this.set_default_text();
  this.set_default_values();
  this.setup();
  this.set_up_html();
  this.register_observers();
}

AbstractChosen.prototype.set_default_values = function() {
  this.click_test_action = (function(_this) {
    return function(evt) {
      return _this.test_active_click(evt);
    };
  })(this);
  this.activate_action = (function(_this) {
    return function(evt) {
      return _this.activate_field(evt);
    };
  })(this);
  this.active_field = false;
  this.mouse_on_container = false;
  this.results_showing = false;
  this.result_highlighted = null;
  this.allow_single_deselect = (this.options.allow_single_deselect != null) && (this.form_field.options[0] != null) && this.form_field.options[0].text === "" ? this.options.allow_single_deselect : false;
  this.disable_search_threshold = this.options.disable_search_threshold || 0;
  this.disable_search = this.options.disable_search || false;
  this.enable_split_word_search = this.options.enable_split_word_search != null ? this.options.enable_split_word_search : true;
  this.group_search = this.options.group_search != null ? this.options.group_search : true;
  this.search_contains = this.options.search_contains || false;
  this.single_backstroke_delete = this.options.single_backstroke_delete != null ? this.options.single_backstroke_delete : true;
  this.max_selected_options = this.options.max_selected_options || Infinity;
  this.inherit_select_classes = this.options.inherit_select_classes || false;
  this.display_selected_options = this.options.display_selected_options != null ? this.options.display_selected_options : true;
  this.display_disabled_options = this.options.display_disabled_options != null ? this.options.display_disabled_options : true;
  this.create_option = this.options.create_option || false;
  this.persistent_create_option = this.options.persistent_create_option || false;
  return this.skip_no_results = this.options.skip_no_results || false;
};

AbstractChosen.prototype.set_default_text = function() {
  if (this.form_field.getAttribute("data-placeholder")) {
    this.default_text = this.form_field.getAttribute("data-placeholder");
  } else if (this.is_multiple) {
    this.default_text = this.options.placeholder_text_multiple || this.options.placeholder_text || AbstractChosen.default_multiple_text;
  } else {
    this.default_text = this.options.placeholder_text_single || this.options.placeholder_text || AbstractChosen.default_single_text;
  }
  this.results_none_found = this.form_field.getAttribute("data-no_results_text") || this.options.no_results_text || AbstractChosen.default_no_result_text;
  return this.create_option_text = this.form_field.getAttribute("data-create_option_text") || this.options.create_option_text || AbstractChosen.default_create_option_text;
};

AbstractChosen.prototype.mouse_enter = function() {
  return this.mouse_on_container = true;
};

AbstractChosen.prototype.mouse_leave = function() {
  return this.mouse_on_container = false;
};

AbstractChosen.prototype.input_focus = function(evt) {
  if (this.is_multiple) {
    if (!this.active_field) {
      return setTimeout(((function(_this) {
        return function() {
          return _this.container_mousedown();
        };
      })(this)), 50);
    }
  } else {
    if (!this.active_field) {
      return this.activate_field();
    }
  }
};

AbstractChosen.prototype.input_blur = function(evt) {
  if (!this.mouse_on_container) {
    this.active_field = false;
    return setTimeout(((function(_this) {
      return function() {
        return _this.blur_test();
      };
    })(this)), 100);
  }
};

AbstractChosen.prototype.results_option_build = function(options) {
  var content, data, _i, _len, _ref;
  content = '';
  _ref = this.results_data;
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    data = _ref[_i];
    if (data.group) {
      content += this.result_add_group(data);
    } else {
      content += this.result_add_option(data);
    }
    if (options != null ? options.first : void 0) {
      if (data.selected && this.is_multiple) {
        this.choice_build(data);
      } else if (data.selected && !this.is_multiple) {
        this.single_set_selected_text(data.text);
      }
    }
  }
  return content;
};

AbstractChosen.prototype.result_add_option = function(option) {
  var classes, option_el;
  if (!option.search_match) {
    return '';
  }
  if (!this.include_option_in_results(option)) {
    return '';
  }
  classes = [];
  if (!option.disabled && !(option.selected && this.is_multiple)) {
    classes.push("active-result");
  }
  if (option.disabled && !(option.selected && this.is_multiple)) {
    classes.push("disabled-result");
  }
  if (option.selected) {
    classes.push("result-selected");
  }
  if (option.group_array_index != null) {
    classes.push("group-option");
  }
  if (option.classes !== "") {
    classes.push(option.classes);
  }
  option_el = document.createElement("li");
  option_el.className = classes.join(" ");
  option_el.style.cssText = option.style;
  option_el.setAttribute("data-option-array-index", option.array_index);
  option_el.innerHTML = option.search_text;
  return this.outerHTML(option_el);
};

AbstractChosen.prototype.result_add_group = function(group) {
  var group_el;
  if (!(group.search_match || group.group_match)) {
    return '';
  }
  if (!(group.active_options > 0)) {
    return '';
  }
  group_el = document.createElement("li");
  group_el.className = "group-result";
  group_el.innerHTML = group.search_text;
  return this.outerHTML(group_el);
};

AbstractChosen.prototype.append_option = function(option) {
  return this.select_append_option(option);
};

AbstractChosen.prototype.results_update_field = function() {
  this.set_default_text();
  if (!this.is_multiple) {
    this.results_reset_cleanup();
  }
  this.result_clear_highlight();
  this.results_build();
  if (this.results_showing) {
    return this.winnow_results();
  }
};

AbstractChosen.prototype.reset_single_select_options = function() {
  var result, _i, _len, _ref, _results;
  _ref = this.results_data;
  _results = [];
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    result = _ref[_i];
    if (result.selected) {
      _results.push(result.selected = false);
    } else {
      _results.push(void 0);
    }
  }
  return _results;
};

AbstractChosen.prototype.results_toggle = function() {
  if (this.results_showing) {
    return this.results_hide();
  } else {
    return this.results_show();
  }
};

AbstractChosen.prototype.results_search = function(evt) {
  if (this.results_showing) {
    return this.winnow_results();
  } else {
    return this.results_show();
  }
};

AbstractChosen.prototype.winnow_results = function() {
  var eregex, escapedSearchText, exact_result, option, regex, results, results_group, searchText, startpos, text, zregex, _i, _len, _ref;
  this.no_results_clear();
  results = 0;
  exact_result = false;
  searchText = this.get_search_text();
  escapedSearchText = searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  zregex = new RegExp(escapedSearchText, 'i');
  regex = this.get_search_regex(escapedSearchText);
  eregex = new RegExp('^' + escapedSearchText + '$', 'i');
  _ref = this.results_data;
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    option = _ref[_i];
    option.search_match = false;
    results_group = null;
    if (this.include_option_in_results(option)) {
      if (option.group) {
        option.group_match = false;
        option.active_options = 0;
      }
      if ((option.group_array_index != null) && this.results_data[option.group_array_index]) {
        results_group = this.results_data[option.group_array_index];
        if (results_group.active_options === 0 && results_group.search_match) {
          results += 1;
        }
        results_group.active_options += 1;
      }
      if (!(option.group && !this.group_search)) {
        option.search_text = option.group ? option.label : option.text;
        option.search_match = this.search_string_match(option.search_text, regex);
        if (option.search_match && !option.group) {
          results += 1;
        }
        exact_result = eregex.test(option.html);
        if (option.search_match) {
          if (searchText.length) {
            startpos = option.search_text.search(zregex);
            text = option.search_text.substr(0, startpos + searchText.length) + '</em>' + option.search_text.substr(startpos + searchText.length);
            option.search_text = text.substr(0, startpos) + '<em>' + text.substr(startpos);
          }
          if (results_group != null) {
            results_group.group_match = true;
          }
        } else if ((option.group_array_index != null) && this.results_data[option.group_array_index].search_match) {
          option.search_match = true;
        }
      }
    }
  }
  this.result_clear_highlight();
  if (results < 1 && searchText.length) {
    this.update_results_content("");
    if (!(this.create_option && this.skip_no_results)) {
      this.no_results(searchText);
    }
  } else {
    this.update_results_content(this.results_option_build());
    this.winnow_results_set_highlight();
  }
  if (this.create_option && (results < 1 || (!exact_result && this.persistent_create_option)) && searchText.length) {
    return this.show_create_option(searchText);
  }
};

AbstractChosen.prototype.get_search_regex = function(escaped_search_string) {
  var regex_anchor;
  regex_anchor = this.search_contains ? "" : "^";
  return new RegExp(regex_anchor + escaped_search_string, 'i');
};

AbstractChosen.prototype.search_string_match = function(search_string, regex) {
  var part, parts, _i, _len;
  if (regex.test(search_string)) {
    return true;
  } else if (this.enable_split_word_search && (search_string.indexOf(" ") >= 0 || search_string.indexOf("[") === 0)) {
    parts = search_string.replace(/\[|\]/g, "").split(" ");
    if (parts.length) {
      for (_i = 0, _len = parts.length; _i < _len; _i++) {
        part = parts[_i];
        if (regex.test(part)) {
          return true;
        }
      }
    }
  }
};

AbstractChosen.prototype.choices_count = function() {
  var option, _i, _len, _ref;
  if (this.selected_option_count != null) {
    return this.selected_option_count;
  }
  this.selected_option_count = 0;
  _ref = this.form_field.options;
  for (_i = 0, _len = _ref.length; _i < _len; _i++) {
    option = _ref[_i];
    if (option.selected) {
      this.selected_option_count += 1;
    }
  }
  return this.selected_option_count;
};

AbstractChosen.prototype.choices_click = function(evt) {
  evt.preventDefault();
  if (!(this.results_showing || this.is_disabled)) {
    return this.results_show();
  }
};

AbstractChosen.prototype.keyup_checker = function(evt) {
  var stroke, _ref;
  stroke = (_ref = evt.which) != null ? _ref : evt.keyCode;
  this.search_field_scale();
  switch (stroke) {
    case 8:
      if (this.is_multiple && this.backstroke_length < 1 && this.choices_count() > 0) {
        return this.keydown_backstroke();
      } else if (!this.pending_backstroke) {
        this.result_clear_highlight();
        return this.results_search();
      }
      break;
    case 13:
      evt.preventDefault();
      if (this.results_showing) {
        return this.result_select(evt);
      }
      break;
    case 27:
      if (this.results_showing) {
        this.results_hide();
      }
      return true;
    case 9:
    case 38:
    case 40:
    case 16:
    case 91:
    case 17:
      break;
    default:
      return this.results_search();
  }
};

AbstractChosen.prototype.clipboard_event_checker = function(evt) {
  return setTimeout(((function(_this) {
    return function() {
      return _this.results_search();
    };
  })(this)), 50);
};

AbstractChosen.prototype.container_width = function() {
  if (this.options.width != null) {
    return this.options.width;
  } else {
    return "" + this.form_field.offsetWidth + "px";
  }
};

AbstractChosen.prototype.include_option_in_results = function(option) {
  if (this.is_multiple && (!this.display_selected_options && option.selected)) {
    return false;
  }
  if (!this.display_disabled_options && option.disabled) {
    return false;
  }
  if (option.empty) {
    return false;
  }
  return true;
};

AbstractChosen.prototype.search_results_touchstart = function(evt) {
  this.touch_started = true;
  return this.search_results_mouseover(evt);
};

AbstractChosen.prototype.search_results_touchmove = function(evt) {
  this.touch_started = false;
  return this.search_results_mouseout(evt);
};

AbstractChosen.prototype.search_results_touchend = function(evt) {
  if (this.touch_started) {
    return this.search_results_mouseup(evt);
  }
};

AbstractChosen.prototype.outerHTML = function(element) {
  var tmp;
  if (element.outerHTML) {
    return element.outerHTML;
  }
  tmp = document.createElement("div");
  tmp.appendChild(element);
  return tmp.innerHTML;
};

AbstractChosen.browser_is_supported = function() {
  if (window.navigator.appName === "Microsoft Internet Explorer") {
    return document.documentMode >= 8;
  }
  if (/iP(od|hone)/i.test(window.navigator.userAgent)) {
    return false;
  }
  if (/Android/i.test(window.navigator.userAgent)) {
    if (/Mobile/i.test(window.navigator.userAgent)) {
      return false;
    }
  }
  return true;
};

AbstractChosen.default_multiple_text = "Select Some Options";

AbstractChosen.default_single_text = "Select an Option";

AbstractChosen.default_no_result_text = "No results match";

AbstractChosen.default_create_option_text = "Add Option";

return AbstractChosen;

})();