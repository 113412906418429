document.addEventListener("turbolinks:load", () => {
	const approveDropdownContent = document.getElementById('approve-report-dropdown-content')

	if (approveDropdownContent) {
		approveDropdownContent.style.display = 'block'

		const approveSelect = document.getElementById('approver-qualification-select')
		if (approveSelect) {
			console.log('approve Select found!')
			approveSelect.addEventListener('change', () => {
				// check and improve this
				const approveConfirm = document.getElementById('approve-confirm-button')
				let path = ''
				path = approveConfirm.getAttribute('href')
				const qual = approveSelect.value

				path += '?qual=' + qual
				approveConfirm.setAttribute('href', path)
			})
		}	
	}

	tippy('#approver-quals-select', {
		content: approveDropdownContent,
		allowHTML: true,
		trigger: 'click',
		theme: 'light-border',
		interactive: true,
		placement: 'bottom',
		animation: 'scale-subtle'
	})
})
