document.addEventListener("turbolinks:load", function() {
  const addFilmButton = document.querySelector('#time-sheet-add-film-line-item')
  if (addFilmButton) {
    addFilmButton.addEventListener('click', manageFilms, false);
  }

  manageFilms()

  function manageFilms() {
    console.log('loaded manageFilms');
    const filmSelects = document.querySelectorAll('.time-sheet-film-description-select')

    if (filmSelects) {
      const smallDiameter = ['< 2" Butt Weld (1)', '2" Weld (1)', '3" Weld (1)', 'Gap Shot (1)'] // $5.50
      const smallSheet = ['3.5" Sheet Film (1)'] // $8
      const largeSheet = ['4.5" Sheet Film (1)'] // $9

      filmSelects.forEach(filmSelect => {
        const input = filmSelect.nextElementSibling
        const priceSelect = input.nextElementSibling
        const hiddenInput = priceSelect.nextElementSibling

        const altValues = ["5.50", "8.00", "9.00"]
        if (altValues.includes(priceSelect.value)) {
          console.log('inside altValues');
          priceSelect.disabled = true
          
          hiddenInput.setAttribute('name', 'time_sheet[films][][price]')
          hiddenInput.value = priceSelect.value
        } else {
          priceSelect.remove(3)
          priceSelect.remove(3)
          priceSelect.remove(3)
        }

        filmSelect.addEventListener('change', (e) => {
          resetFilmSelect = () => {

            if (priceSelect.options) {
              removeOptions(priceSelect)
            }
          
            const option1 = document.createElement("option")
            option1.value = "1.50"
            option1.text = "$1.50"
          
            const option2 = document.createElement("option")
            option2.value = "1.75"
            option2.text = "$1.75"
          
            priceSelect.add(option1)
            priceSelect.add(option2)
          
            priceSelect.disabled = false
          }
          
          function removeOptions(selectElement) {
            var i, L = selectElement.options.length - 1;
            for(i = L; i >= 0; i--) {
              selectElement.remove(i);
            }
          }

          createOption = (value) => {
            const option = document.createElement("option")
            option.value = value
            option.text = `$${value}`
            return option
          }

          addOption = (option) => {
            priceSelect.add(option)
            priceSelect.selectedIndex = "0"
            priceSelect.disabled = true
          }

          addHidden = (value) => {
            hiddenInput.setAttribute('name', 'time_sheet[films][][price]')
            hiddenInput.value = value
          }
          
          if (smallDiameter.includes(e.target.value)) {
            const smallDiameterPrice = '5.50'
            resetFilmSelect()
            removeOptions(priceSelect)
            option = createOption(smallDiameterPrice)
            addOption(option)
            addHidden(smallDiameterPrice)
          } else if (smallSheet.includes(e.target.value)) {
            const smallSheetPrice = '8.00'
            resetFilmSelect()
            removeOptions(priceSelect)
            option = createOption(smallSheetPrice)
            addOption(option)
            addHidden(smallSheetPrice)
          } else if (largeSheet.includes(e.target.value)) {
            const largeSheetPrice = '9.00'
            resetFilmSelect()
            removeOptions(priceSelect)
            option = createOption(largeSheetPrice)
            addOption(option)
            addHidden(largeSheetPrice)
          } else {
            resetFilmSelect()
          }

        })
      })
    }
  }

})
